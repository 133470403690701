import { signOutProcess } from '../utils/signout';

const os = require('os');

const hostName = os.hostname();
const SUBNAME_REACT = 'app.';
const SUBNAME_SSR = 'srv.';
const SUBNAME_REACT_SUPERUSER = 'admin.';
let baseApiUrl;
let newBaseApiUrl;
let baseFilesUrl;
let baseNotifyUrl;
let domainHome;
let subdomenPhotographer;
let subdomenLogOut;
let domainSsrHome;
let loggingURL;
let loggingFileURL;
let paymentApiUrl;
let baseNewSuperuserUrl;
if (hostName.includes('localhost') || hostName.includes('127.0.0.1')) {
  baseApiUrl = `http://${hostName}:3001/v1`;
  newBaseApiUrl = `http://${hostName}:3001/v2`;
  baseFilesUrl = `http://${hostName}:3002/upload/content/`;
  baseNotifyUrl = `http://${hostName}:3003/v2`;
  domainHome = `http://${hostName}:3000`;
  subdomenPhotographer = `http://${hostName}:3000`;
  subdomenLogOut = `${domainHome}/user/login`;
  loggingURL = `http://${hostName}:3001`;
  loggingFileURL = `http://${hostName}:3002`;
  paymentApiUrl = `http://${hostName}:3004/v2`;
  baseNewSuperuserUrl = `http://${hostName}:3050/`;
} else if (hostName.includes(SUBNAME_REACT)) {
  const hostNameUpdated = hostName.replace(SUBNAME_REACT, '');
  loggingURL = `https://api.${hostNameUpdated}`;
  loggingFileURL = `https://files.${hostNameUpdated}`;
  baseApiUrl = `https://api.${hostNameUpdated}/v1`;
  newBaseApiUrl = `https://api.${hostNameUpdated}/v2`;
  baseFilesUrl = `https://files.${hostNameUpdated}/upload/content/`;
  baseNotifyUrl = `https://notify.${hostNameUpdated}/v2`;
  domainHome = `https://${hostNameUpdated}`;
  subdomenPhotographer = `https://${hostNameUpdated}`;
  domainSsrHome = `https://${SUBNAME_SSR}${hostNameUpdated}`;
  subdomenLogOut = `${domainSsrHome}/user/login`;
  paymentApiUrl = `https://payments.${hostNameUpdated}/v2`;
  baseNewSuperuserUrl = `http://${SUBNAME_REACT_SUPERUSER}${hostNameUpdated}`;
} else if (hostName.includes(SUBNAME_SSR)) {
  const hostNameUpdated = hostName.replace(SUBNAME_SSR, '');
  loggingURL = `https://api.${hostNameUpdated}`;
  loggingFileURL = `https://files.${hostNameUpdated}`;
  baseApiUrl = `https://api.${hostNameUpdated}/v1`;
  newBaseApiUrl = `https://api.${hostNameUpdated}/v2`;
  baseFilesUrl = `https://files.${hostNameUpdated}/upload/content/`;
  baseNotifyUrl = `https://notify.${hostNameUpdated}/v2`;
  domainHome = `https://${hostNameUpdated}`;
  subdomenPhotographer = `https://${hostNameUpdated}`;
  domainSsrHome = `https://${SUBNAME_SSR}${hostNameUpdated}`;
  subdomenLogOut = `${domainSsrHome}/user/login`;
  paymentApiUrl = `https://payments.${hostNameUpdated}/v2`;
  baseNewSuperuserUrl = `http://${SUBNAME_REACT_SUPERUSER}${hostNameUpdated}`;
} else {
  //TODO Warn
  signOutProcess();
  console.log('Error resolve domain name');
}

export const APP_CONSTANS = {
  GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    (
      ['dev', 'test', 'stage'].some(dom => window.location.hostname.includes(dom))
        ? 'AIzaSyAzVI41JXoZkX5qMJKtMMEJ_ImslkGfY5I'
        : 'AIzaSyCcb3BMRlLwy7hjOv4eIO0LnzFm6RXB5f8'
    ),
  QUERY_WEB_VIEW_FROM_APP: 'mobile_app',
  IOS_APPSTORE_LINK: 'itms-apps://itunes.apple.com/app/fotofetch/id6461381403',
  CURRENT_VERSION: '2.31.4',
  BASE_API_URL: baseApiUrl,
  NEW_BASE_API_URL: newBaseApiUrl,
  BASE_FILES_URL: baseFilesUrl,
  BASE_NOTIFY_URL: baseNotifyUrl,
  BASE_NEW_SUPERUSER_UI: baseNewSuperuserUrl,
  PAYMENT_API_URL: paymentApiUrl,
  DOMAIN_HOME: domainHome,
  DOMAIN_LOGIN: subdomenLogOut,
  SUB_DOMEN_PHOTOGRAPHER: subdomenPhotographer,
  SUB_DOMEN_SSR: domainSsrHome,
  loggingURL: loggingURL,
  loggingFileURL: loggingFileURL,
  SESSION_LIFE_IN_MINUTES: 120,
  ONE_MINUTE_IN_MS: 60000,
  LS_TOKEN: 'FF_TOKEN',
  LS_SESSION_EXP: 'FF_SESSION_EXP',
  LS_INTERVAL_JOBS_ID: 'FF_INTERVAL_JOBS_ID',
  TOKEN_URL: 'auth_token',
  ID_URL: 'id',
  TYPE_URL: 'type',
  ORDER: 'order',
  ORDERS: 'orders',
  JOB: 'job',
  JOBS: 'jobs',
  FULFILMENT: 'fulfilment',
  USERS: {
    PHOTOGRAPHER: 'Photographer',
    CUSTOMER: 'Customer',
    SUPPORT: 'support',
    SUPERUSER: 'superuser',
  },
  DRONE: {
    DroneLicenceNumber: 'a13e8a1f-d133-431b-8304-eb402ea91c40',
    DroneLicenceIssued: 'a162356e-6436-4b5c-96f1-29bb0ca2604b',
    DroneLicence: 'a1e677d3-252e-48ef-a794-c57b521d54aa',
    DroneLicenceRequired: 'DroneLicence = valid',
  },
  STAB: {
    Stabilization: 'a11e1d21-50d7-4058-a31e-42135f09c218',
    StabilizationRequired: 'Stabilization = True',
  },
  PHOTOGRAPHER_PROPS: {
    LEVEL: {
      CODE: 'a3cc8111-d107-49a8-a5e8-4bce9b1cf341',
      AMATEUR: 'Unrated',
      ENTHUSIAST: 'Enthusiast',
      PROFESSIONAL: 'Professional',
    },
    LOCATION_FROM: {
      CODE: '94291a54-d88d-4ff2-be70-b77b7c46746a',
      LOCATION: 'location',
      ADDRESS: 'address',
    },
    MAX_JOBS: 'a032c8a0-d190-4db5-8f5b-6ba8a3761e3e',
    DEFAULT_PAY: 'a6a3f067-7629-465e-9487-ac2fd7a0a710',
    USE_PAYPAL: 'a99d34dd-af40-45c5-b342-f594517f83bd',
    USE_VENMO_ID: 'a8612805-f592-449c-a6e5-96f7b780911c',
    USE_VENMO_NO: 'a81ce075-8b13-498f-8906-e2e7d183e38f',
    JOB_LOOKUP_RADIUS: '67c96f33-6035-4203-96cf-2ced600c5640',
    IS_VIEW_IOS_APP_NOTIFY_CODE: 'iosAppNotification__doNotChange',
  },
  NOTIFICATIONS: {
    PHOTOGRAPHER: {
      STATUSES: {
        NotifyTypePhotographerJobRejectedByEditor:
          'NotifyTypePhotographerJobRejectedByEditor',
        NotifyTypePhotographerJobReturnedByEditor:
          'NotifyTypePhotographerJobReturnedByEditor',
        NotifyTypePhotographerJobPaid: 'NotifyTypePhotographerJobPaid',
        NotifyTypePhotographerOrderTimeout:
          'NotifyTypePhotographerOrderTimeout',
        NotifyTypePhotographerJobCompleted:
          'NotifyTypePhotographerJobCompleted',
        NotifyTypePhotographerJobCanBeSubmitted:
          'NotifyTypePhotographerJobCanBeSubmitted',
        NotifyPhotographerFulfillmentExpiration:
          'NotifyPhotographerFulfillmentExpiration',
      },
      COMMUNICATIONS: {
        NotifyTypePhotographerAboutNewJobs:
          'NotifyTypePhotographerAboutNewJobs',
        NotifyTypePhotographerJobAssignedToUser:
          'NotifyTypePhotographerJobAssignedToUser',
        NotifyTypePhotographerNewCommentFromEditor:
          'NotifyTypePhotographerNewCommentFromEditor',
        NotifyTypePhotographerRequestApproved:
          'NotifyTypePhotographerRequestApproved',
        NotifyTypePhotographerRequestRejected:
          'NotifyTypePhotographerRequestRejected',
        NotifyTypeEditorCommentLevelUpgrade:
          'NotifyTypeEditorCommentLevelUpgrade',
        NotifyTypeEditorCommentDroneLicense:
          'NotifyTypeEditorCommentDroneLicense',
      },
    },
    CUSTOMER: {
      notify_type_customer_order_completed: 'Notify if order was completed',
      notify_type_customer_order_timeout: 'Notify if order timeout',
      notify_type_customer_order_approved: 'Notify if order was approved',
      notify_type_customer_order_status_changed:
        'Notify if order changed status',
      notify_type_customer_order_published: 'Notify if order was published',
      notify_type_customer_new_comment_from_editor:
        'Notify if editor left a comment in order',
      notify_type_customer_order_rejected_by_editor:
        'Notify if order was rejected',
    },
    EDITOR: {
      notify_type_editor_new_comment_from_customer:
        'Notify if customer left a comment on job',
      notify_type_editor_new_comment_from_photographer:
        'Notify if photographer left a comment on job',
    },
  },
  ORDER_STATUSES_GUID_BY_CODE: {
    DRAFT_ORDER: '563a3e9d-e756-43b4-bc21-3e90ecaa7a0c',
    TEMPLATE_ORDER: '7d664841-fefe-440d-a6e7-7454371904ee',
    SUBMITTED_ORDER: '4e1f8868-06a3-4614-934f-0f7813d99592',
    PENDING_ORDER: 'dbe718a1-6774-4bb3-ae71-d942c11bc53f',
    APPROVED_ORDER: '2d64523c-8123-4993-8f15-484bd29968ab',
    REJECTED_ORDER: 'feaff639-f8e0-4c9b-83a7-3442dffaaa9f',
    PUBLISHED_ORDER: '351fe340-4615-40df-98b2-23097e779f8f',
    ACCEPTED_ORDER: '422068bf-8945-49fe-818a-574e6a4b0ada',
    COMPLETED_ORDER: '830e7ac6-f880-43c5-97e9-c3e58a399779',
    CANCELLED_ORDER: 'db6f747a-8e50-495f-b575-e31bed45ea2e',
    EXPIRED_ORDER: '865bbcaa-305e-41a5-bd7a-080ca6e5c3af',
  },
  FULFILMENT_STATUSES_GUID_BY_CODE: {
    ACCEPTED_JOB: 'c90b48e3-2b6d-401e-a828-780b6e00d54d',
    SUBMITTED_JOB: 'c600cbae-84e9-4409-90e2-d2caab303498',
    PENDING_JOB: 'bf65bb1f-b8c9-461a-9d20-e88dd2254506',
    REJECTED_JOB: 'c39f2f70-b76e-4b09-824c-638f55433c68',
    RETURNED_JOB: 'c1a0f17f-7a7d-49d2-934e-7c4b9fa81102',
    COMPLETED_JOB: 'c14f210e-fc32-416b-a8d5-76dbe317888f',
    DECLINED_JOB: 'c10ee3cb-a927-4d32-9100-cb83a32da174',
    CANCELLED_JOB: 'b72cc1ae-f9d7-4bfe-9246-52476ed6d7ee',
    EXPIRED_JOB: 'c070ca57-827b-4f3d-8022-37baaa0aaffa',
  },
  FULFILMENT_CONTENT_STATUSES_GUID_BY_CODE: {
    PROCESSING_CONTENT: '3e66de3b-b089-4392-89a3-a3f1d43c0832',
    PROCESSED_CONTENT: '616a2568-967b-4dfb-9265-61ab5ab5201a',
    SUBMITTED_CONTENT: 'be3b5c68-c77c-4a95-9c44-aa5268f28b8a',
    APPROVED_CONTENT: 'bdfd03ad-f644-4e26-a281-c7268221b8c6',
    REJECTED_CONTENT: 'bdccf0b7-3d61-4a64-9a2b-105c0a5193fb',
    DELIVERED_CONTENT: 'bd7aab70-238d-4320-9426-22ba91ccca55',
    FAILED_CONTENT: 'bc8e2b2b-ec0a-4dd6-9048-2eafee0126ce',
    DUPLICATED_CONTENT: '90b4c4eb-2198-48be-bf01-1b51535a8148',
    OUT_OF_FOCUSED_CONTENT: '21efd565-d91b-48dd-b45d-410abf8e9622',
    CONVERTING_CONTENT: 'b63afbc2-8dd6-4a37-ac08-8902a5b88fc6',
  },
  FULFILMENT_STATE_REQUEST: {
    GUID_REQUEST_STATE_SUBMITTED: 'baa912cc-6246-47fa-8c10-1a2c2c010714',
    GUID_REQUEST_STATE_APPROVED: 'ba2a56dd-31f9-4c8f-9c4d-469b85e32616',
    GUID_REQUEST_STATE_REJECTED: 'b8327cce-ee33-4143-8c00-08eb9427b38f',
    GUID_REQUEST_STATE_FAILED: 'b7ccffbc-59ef-46f9-b35d-9309c5074e54',
  },
  REQUESTS: {
    PROPERTY_NAME: 'request_type',
    DRONE_LICENSE: 'drone_license',
    PHOTOGRAPHER_LEVEL: 'level_upgrade',
    EXTEND_FULFILMENT_TIME: 'ExtendTime',
  },
  REQUESTS_STATUSES: {
    SUBMITTED: 'baa912cc-6246-47fa-8c10-1a2c2c010714',
    APPROVED: 'ba2a56dd-31f9-4c8f-9c4d-469b85e32616',
    REJECTED: 'b8327cce-ee33-4143-8c00-08eb9427b38f',
    FAILED: 'b7ccffbc-59ef-46f9-b35d-9309c5074e54',
  },
  MESSAGING: {
    STATUS: {
      OK: 'OK',
      ERROR: 'ERROR',
    },
    NETWORK_ERROR: {
      TYPE: 'network error',
      ERROR: 'Network error, please try again',
    },
    PASSWORD_CHANGE: {
      TYPE: 'password change',
      OK: 'Your password successfully changed',
      ERROR: 'Your current password is wrong',
    },
    NOTIFICATIONS_CHANGE: {
      TYPE: 'notifictions change',
      OK: 'Your notifictions status successfully changed',
      ERROR: 'Network error, please try again',
    },
    PAYMENT_CHANGE: {
      TYPE: 'payment change',
      OK: 'Your payment information successfully changed',
      ERROR: 'Network error, please try again',
    },
    ACCOUNT_CHANGE: {
      TYPE: 'account change',
      OK: 'Your account information successfully changed',
      ERROR: 'Network error, please try again',
    },
    ADDRESS_CHANGE: {
      TYPE: 'address change',
      OK: 'Your address information successfully changed',
      ERROR: 'Network error, please try again',
    },
    PROFILE_CHANGE: {
      TYPE: 'profile change',
      OK: 'Your profile information successfully changed',
      ERROR: 'Network error, please try again',
    },
    ACCEPT_ORDER: {
      TYPE: 'accept order',
      OK: 'This job has been assigned to you, you have 48 hours to complete the job.',
      ERROR: 'Job is no longer available',
    },
    ACCEPT_ORDER_403: {
      TYPE: 'accept order 403',
      ERROR:
        'You are not permitted to accept a job you have previously accepted and then declined.',
    },
    UNAVAILABLE_DRONE: {
      TYPE: 'accept order 403',
      ERROR:
        "You are not permitted to accept a job you don't have a drone license.",
    },
    UNAVAILABLE_ORDER: {
      TYPE: 'accepted order',
      OK: 'This job is no longer available.',
      ERROR: 'Job is no longer available',
    },
    DECLINE_FULFILMENT: {
      TYPE: 'decline fulfilment',
      OK: 'Job is successfully declined',
      ERROR: 'Network error, please try again',
    },
    REQUEST_TIME_EXTENSION: {
      TYPE: 'request time extension',
      OK: 'Time Extension request is created. You will be notified about decision by email.',
      ERROR: 'Network error, please try again',
    },
    SUBMIT_JOB: {
      TYPE: 'submit job',
      OK: 'Content has been successfully submitted for review.',
      ERROR: 'Network error, please try again',
    },
    LEVEL_UPGRADE: {
      TYPE: 'level upgrade',
      OK: 'Request is created. You will be notified about editor decision by email.',
      ERROR: 'Network error, please try again',
    },
    CHECK_IN: {
      TYPE: 'check in',
      OK: 'You have successfully checked-in.',
      ERROR: 'Network error, please try again',
    },
    CHECK_OUT: {
      TYPE: 'check out',
      OK: 'You have successfully checked-out.',
      ERROR: 'Network error, please try again',
    },
  },
  COMPLICATION_GUID_BY_CODE: {
    CONTENT_TYPE: 'f2b76b67-b451-4839-85dd-af1b0c71facb',
    IMAGE_ORDER_TYPE: '85896497-579f-4777-86bd-c2bd6adf4534',
    START_DATE: 'b6280c61-5c61-41ef-aa17-84383e758841',
    END_DATE: 'b41cd441-78db-4638-8f98-14abc9a29417',
    VIDEO_ORDER_TYPE: 'e44b3c09-4023-4096-aa5a-0d5ba0ceee7d',
    VIDEO_RESOLUTION: '9b2dc462-c540-4ff5-8ecc-59af022aefcd',
    VIDEO_CONTENT_LENGTH: 'fbd7a046-5029-4255-8f26-5cd99594e28e',
    URGENCY: 'b9a7ea5b-d1f8-432c-a757-d43396f67523',
    TIME_OF_SHOT: '935a2f15-68c3-4e6c-baec-4f1279072007',
    IMAGE_FILE_TYPE: '55f87b3c-bc52-402b-be99-1253cf165de9',
  },
  IMAGE_FILE_TYPE_CHILDREN: {
    JPG: '71407526-15d1-4967-a1b0-a6aefa290416',
    RAW: '30720f6c-0cf6-4459-9262-6033e1c0eb54',
  },
  SYSTEM_PREFERENCES_ID: {
    MAX_PIXELS: {
      id: 'd6b8f53c-e73d-4243-b27a-0cd16f4873b2',
      code: 'maxDimension',
    },
    MIN_PIXELS: {
      id: 'b062b821-076d-4939-ad85-acb941250356',
      code: 'minDimension',
    },
    MAX_FILE_SIZE: {
      id: '061c914a-e87a-49a6-a867-c07a91365beb',
      code: 'maxSize',
    },
    MIN_FILE_SIZE: {
      id: '6528d584-5dff-43a4-8d55-1fdcab1abd86',
      code: 'minSize',
    },
    REQUIRED_MAX_FILESIZE: {
      id: '051182ce-a6d0-4884-8516-5fa8cb4ce260',
      code: 'RequiredMaxFileSize',
    },
    REQUIRED_MIN_FILESIZE: {
      id: 'c2c4a309-06da-4c45-89c8-d3489fd3b7ae',
      code: 'RequiredMinFileSize',
    },
    REQUIRED_MAX_PIXELS: {
      id: 'c40ab391-15f5-47d2-b9f8-8ec4a1cbe631',
      code: 'RequiredMaxPixels',
    },
    REQUIRED_MIN_PIXELS: {
      id: '1554a23e-497f-4da2-83fe-300eef812ca8',
      code: 'RequiredMinPixels',
    },
    CHECKIN_MIN_DISTANCE: {
      id: '3894c727-2675-4054-a027-87038ef0b801',
      code: 'CheckInMinDistance',
    },
  },
  ONBOARDINGS: {
    CHECK_POINT: 'e78b465d-fc24-42cf-860d-676bac2df8e7',
    MAIN: '40367c96-cd81-4dec-bd47-593303563c97',
  },
  PURCHASE_METHODS: {
    PAYPAL: 'paypal',
    VENMO: 'venmo',
  },
  PURCHASE_METHODS_GUIDS: {
    PAYPAL: "9a9e31c8-aee4-491b-890b-7b0721199398",
    VENMO: "c1377125-dee4-437b-aa7b-f85f3f291222",
    AMAZON: "20bb7db3-316b-4bdf-bea0-632e22c91e82",
    WALMART: "e0b08023-7204-438e-bc18-8f242094ee0a",
    KROGER: "dbc34221-1b5d-48a6-b7a4-e9e92d6e4497",
    STARBUCKS: "166143d6-0429-4184-bd19-d9c2ac1846f9",
    APPLE: "6d38bbee-6197-433e-b88f-194ac1edb644",
    MASTERCARD: "dd0550df-5d33-4c86-8611-05441ca86d8c",
    VISA: "50d9a02a-79da-45c5-955f-797bf829259f",
  },
};
